import React, { useEffect, useContext, useState } from "react"
import { Flex, Box } from "theme-ui"
import CaretDown from "../assets/caret-down.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"

const Course = (props) => {
  const { name, text, image } = props.data
  const { prefix } = props
  const open = props.open
  const setOpen = props.setOpen
  return (
    <Flex
      sx={{
        overflow: "hidden",
        position: "relative",
        width: props.full ? "100%" : ["100%", "100%", "calc(100%/3)"],
        borderBottom: "2px solid black",
        borderRight: ["none", "none", "2px solid black"],
        minHeight: props.full ? "none" : ["none", "none", "none"],
        height: props.full ? "auto" : "auto",
        flexDirection: "column",
        justifyContent: "space-between",
        background: !open ? "#b7b7b7" : "transparent",
        paddingBottom: '30px',
      }}
      p={20}
    >
      <Flex
        sx={{
            height: props.full ? "auto" : ['auto', 'auto', '100%'],
            flexDirection: 'column',
            justifyContent: open ? 'flex-start' : 'center',
        }}
      >
        <Box
          sx={{
            h3: {
              fontWeight: "bold",
              fontSize: "2em",
              lineHeight: "1.25em",
            },
          }}
        >
          <h3>{name}</h3>
        </Box>
        {open ? (<Flex
          sx={{
            flexDirection: ["column", "column", "row"],
            alignItems: ["flex-end", "flex-end", "center"],
          }}
        >
          <Box
            sx={{
              fontSize: "1.1em",
              lineHeight: "1.25em",
              li: {
                listStyleType: "disc",
                marginBottom: "10px",
              },
            }}
            dangerouslySetInnerHTML={{
              __html: text.childMarkdownRemark.html,
            }}
          />
          {image && (
            <Box
              p={20}
              sx={{
                position: "absolute",
                right: "-10px",
                width: ["100px", "100px", "350px"],
                opacity: ["0", "0.1"],
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          )}
        </Flex>) : (
        <Flex
            sx={{
                marginTop: '10px',
                textTransform: "uppercase",
                fontSize: "1em",
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                img: {
                    width: "12px",
                    transform: "rotate(-90deg)",
                    filter: "invert(1)",
                    marginLeft: "5px",
                }, 
            }}
            onClick={() => setOpen(props.courses.map((o,i) => i === props.index ? true : false))}
        >
            <Box>Read more</Box>
            <img src={CaretDown} />
        </Flex>)}
      </Flex>
    </Flex>
  )
}

export default Course
