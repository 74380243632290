import React from "react"

const ProgramApplicationTemplate = () => {
  return (
    <form style={{ display: "none" }} data-netlify="true" name="blonde-program">
      <input type="hidden" name="position" />
      <input type="hidden" name="name" />
      <input type="hidden" name="email" />
      <input type="hidden" name="phone" />
      <input type="hidden" name="application" />
      <input type="hidden" name="portfolio" />
    </form>
  )
}

export default ProgramApplicationTemplate
