import React, { useEffect, useContext, useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { Flex, Box } from "theme-ui"
import CaretDown from "../assets/caret-down.svg"
import Logo from "../assets/logo-green.svg"
import LogoBorder from "../assets/logo-border.svg"
import { InterfaceContext } from "../context/interface"
import ProgramApplication from "../components/program-application"
import Course from "../components/course"
import ProgramApplicationTemplate from "../components/program-application-template"

const Program = ({ data, location, pageContext }) => {
  const { locale } = useContext(InterfaceContext)
  const { program } = data
  const { type } = pageContext

  const [lang] = locale.split("-")
  let prefix = "/"
  if (lang !== "en") {
    prefix = `/${lang}/`
  }

  const [content, setContent] = useState("parts")
  const [open, setOpen] = useState(
    program.courses.map((c, i) => (i === 0 ? true : false))
  )
  useEffect(() => {
    switch (type) {
      case "participants": {
        setContent("participants")
        break
      }
      case "apply": {
        setContent("courses")
        break
      }
      default:
        setContent("parts")
    }
  }, [type])

  const AboveFold = () => (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: ["flex-start", "flex-start", "center"],
        height: "100vh",
      }}
      p={20}
    >
      <Flex
        sx={{
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: "200px",
            filter: "brightness(0)",
          }}
          pb={15}
        >
          <GatsbyImage image={program.logo.gatsbyImageData} alt="" />
        </Box>
        <Box
          sx={{
            fontWeight: 200,
            fontSize: "1.25em",
            maxWidth: "750px",
            width: ["100%", "100%", "auto"],
          }}
        >
          <Box
            sx={{
              paddingLeft: ["0", "0", "30px"],
              whiteSpace: "pre-wrap",
              p: {
                margin: "0",
              },
            }}
          >
            <Box
              sx={{
                h1: {
                  fontSize: "15px",
                  fontWeight: 200,
                  my: "0",
                  display: "inline",
                  fontFamily:
                    "'Formular', Tahoma, Arial, Helvetica, sans-serif",
                },
                p: {
                  display: "inline",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: program.introduction.childMarkdownRemark.html,
              }}
            />
            <Flex
              mt={4}
              sx={{
                alignItems: "center",
                fontSize: 2,
                fontWeight: 300,
                color: "#717A89",
              }}
              pb={5}
            >
              SCROLL DOWN
              <Box sx={{ height: 8, filter: "brightness(0)" }} ml={1}>
                <img height="100%" src={CaretDown} />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
  )

  const Header = () => (
    <Flex
      px={20}
      py={10}
      sx={{
        background: "#e4e5e0",
        alignItems: "center",
        borderBottom: "2px solid black",
        justifyContent: "space-between",
        zIndex: "1",
      }}
    >
      <Flex
        sx={{
          width: "125px",
          alignItems: "center",
        }}
      >
        <Link style={{ display: "flex" }} to={`${prefix}${program.slug}`}>
          <GatsbyImage image={program.logo.gatsbyImageData} alt="" />
        </Link>
      </Flex>
      <Flex
        sx={{
          width: "40px",
        }}
      >          <Link to={prefix}>
      
        <img height="100%" src={Logo} />
        </Link>
      </Flex>
    </Flex>
  )

  const Participant = (props) => {
    const { age, name, description, images } = props.data

    return (
      <Flex
        sx={{
          width: ["100%", "100%", "calc(100%/3)"],
          borderRight: ["none", "none", "2px solid black"],
          "&:last-child": {
            borderRight: "none",
            paddingBottom: ["40px", "20px", "40px"],
          },
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        p={[20, 20, 40]}
        px={[20, 20, 60]}
      >
        <Box>
          <Box
            sx={{
              h3: {
                fontSize: "2em",
                lineHeight: "1.25em",
              },
            }}
          >
            <h3>{name}</h3>
            <Box sx={{ textTransform: "uppercase" }}>
              {program.ageLabel} {age}
            </Box>
          </Box>
          <Box
            sx={{
              fontSize: "1.25em",
              lineHeight: "1.25em",
            }}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {images.map((image) => (
            <Box>
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          ))}
        </Box>
      </Flex>
    )
  }

  const Part = (props) => {
    const { name, text } = props.data
    return (
      <Flex
        sx={{
          width: ["100%", "100%", "calc(100%/3)"],
          borderBottom: "2px solid black",
          borderRight: ["none", "none", "2px solid black"],
          height: ["unset", "unset", "100%"],
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        p={20}
      >
        <Box>
          <Box
            sx={{
              h3: {
                fontSize: "2em",
                lineHeight: "1.25em",
              },
            }}
          >
            <h3>{name}</h3>
          </Box>
          <Box
            sx={{
              fontSize: "1.25em",
              lineHeight: "1.25em",
            }}
            dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
          />
        </Box>
      </Flex>
    )
  }

  const Button = ({ onClick, children, sx }) => {
    return (
      <Box
        py={10}
        px={20}
        sx={{
          fontSize: "1.25em",
          textAlign: "center",
          borderBottom: "2px solid black",
          cursor: "pointer",
          ...sx,
          a: {
            textDecoration: "none",
          },
        }}
        onClick={onClick}
      >
        {children}
      </Box>
    )
  }

  const Apply = (props) => {
    return (
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          background: "#FFFFFF",
          color: "white",
          textAlign: "left",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <ProgramApplication data={program.application} />
        </Box>
      </Flex>
    )
  }

  const Sidebar = (props) => {
    const { childMarkdownRemark } = props.data
    return (
      <Flex
        sx={{
          width: ["100%", "100%", "25%"],
          minHeight: ["none", "none", "none"],
          flexDirection: "column",
        }}
      >
        {content === "courses" ? (
          <Apply />
        ) : (
          <Box sx={{ flexGrow: "1" }}>
            <Link to={`${prefix}${pageContext.slug}/apply`}>
              <Button
                sx={{
                  background: "#069948",
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "1.75em",
                  textTransform: "uppercase",
                  textDecoration: "none",
                }}
              >
                {program.applicationLink.label}
              </Button>
            </Link>
            <Box
              p={20}
              sx={{
                fontSize: "1.25em",
                lineHeight: "1.25em",
                flexGrow: "1",
              }}
              dangerouslySetInnerHTML={{ __html: childMarkdownRemark.html }}
            />
          </Box>
        )}
        {content === "parts" && (
          <Flex
            sx={{
              flexDirection: "column",
              borderTop: "2px solid black",
            }}
          >
            <ProgramApplicationTemplate />
            {program.navigation.items.map((item) => {
              let link = prefix
              if (item.link?.slug && item.slug) {
                link = `${link}${item.link.slug}${item.slug}`
              } else if (item.slug) {
                link = `${link}${item.slug}`
              }
              return (
                <Link style={{ textDecoration: "none" }} to={link}>
                  <Button sx={{ textTransform: "uppercase" }}>
                    {item.label}
                  </Button>
                </Link>
              )
            })}
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <Box
      sx={{
        background: "#ffffff",
      }}
    >
      {content == "parts" && <AboveFold />}
      <Flex
        sx={{
          minHeight: ["unset", "unset", "100vh"],
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Header />
        {content === "participants" && (
          <Flex
            sx={{
              flexWrap: "wrap",
              flexGrow: "1",
            }}
          >
            {program.participants.map((participant) => (
              <Participant data={participant} />
            ))}
          </Flex>
        )}
        {content !== "participants" && (
          <Flex
            sx={{
              flexDirection: ["column", "column", "row"],
              flexGrow: "1",
            }}
          >
            <Flex
              sx={{
                flexWrap: "wrap",
                width: ["100%", "100%", "75%"],
                justifyContent: "flex-end",
              }}
            >
              {content === "parts" && (
                <Flex
                  sx={{
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Flex
                    sx={{
                      flexGrow: "1",
                      flexWrap: "wrap",
                      height: "100%",
                    }}
                  >
                    {program.parts.map((part) => (
                      <Part data={part} />
                    ))}
                  </Flex>
                  <Box sx={{ flexShrink: "0" }}>
                    <Course
                      open={true}
                      full
                      data={program.courses[3]}
                      prefix={prefix}
                    />
                  </Box>
                </Flex>
              )}

              {content === "courses" &&
                program.courses.map((course, index) => {
                  if (index < 3)
                    return (
                      <Course
                        courses={program.courses}
                        open={open[index]}
                        index={index}
                        setOpen={setOpen}
                        data={course}
                        prefix={prefix}
                      />
                    )
                })}
            </Flex>
            <Sidebar data={program.description} />
          </Flex>
        )}

        <Box
          sx={{
            textTransform: "uppercase",
            fontSize: "1em",
            fontWeight: "bold",
            alignItems: "center",
            position: "absolute",
            bottom: "0",
            left: "0",
            margin: "10px 20px",
            display: ["none", "none", "flex"],
            a: {
              textDecoration: "none",
            },
            img: {
              width: "12px",
              transform: "rotate(90deg)",
              filter: "invert(1)",
              marginRight: "5px",
            },
          }}
        >
          
          <Link to="../">Back</Link>

        </Box>
      </Flex>
    </Box>
  )
}

export default Program

export const pageQuery = graphql`
  query ProgramContent($slug: String!, $locale: String!) {
    program: contentfulProgram(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      slug
      ageLabel
      courseLabel
      introduction {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      logo {
        gatsbyImageData(quality: 100, placeholder: NONE)
      }
      parts {
        name
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      participants {
        age
        name
        description {
          childMarkdownRemark {
            html
          }
        }
        images {
          gatsbyImageData(quality: 100)
        }
      }
      courses {
        name
        image {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      application {
        heading
        body {
          childMarkdownRemark {
            html
          }
        }
        jobTitles
      }
      navigation {
        items {
          ...navigationItemFields
        }
      }
      applicationLink {
        ...navigationItemFields
      }
    }
  }
`
